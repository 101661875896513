.noUi-value-horizontal {
    white-space: unset;
    width: 15%;
}

.noUi-handle:after, .noUi-handle:before {
    content: unset;
}

.noUi-vertical .noUi-handle,
.noUi-horizontal .noUi-handle {
    border-radius: 50%;
    background: transparent linear-gradient(180deg, #5AD1EF 0%, #21A4C4 51%, #1098B9 52%, #007793 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 20px #00000076;
    border: 1px solid #007793;
    width: 32px;
    height: 32px;
}


.noUi-horizontal .noUi-handle {
    right: -16px;
    top: -3px;
}

.noUi-handle:focus {
    outline: none;
}

.noUi-horizontal {
    height: 26px;
    position: relative;
    border-radius: 0;
}

.noUi-pips-horizontal {
    transform: translateY(-20px);
}

.noUi-target {
    background: var(--brand-blue);
    border-radius: 0;
    border: unset;
    box-shadow: unset;
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 40px;
    transform: translateY(-23px);
    background: var(--brand-secondary);
}

.noUi-horizontal:before {
    content: '';
    position: absolute;
    left: -13px;
    height: 26px;
    top: 0;
    background: var(--brand-primary);
    width: 26px;
    border-radius: 26px;
    z-index: 1;
}

.noUi-horizontal:after {
    content: '';
    position: absolute;
    right: -13px;
    height: 26px;
    top: 0;
    background: var(--brand-blue);
    width: 26px;
    border-radius: 26px;
    z-index: -1;
}

.noUi-connect {
    background: var(--brand-primary);
}

.noUi-pips {
    color: unset;
}

.noUi-horizontal .noUi-pips {
    font-size: 20px;
}

.noUi-vertical {
    width: 20px;
    height: 200px;
    position: relative;
}

.noUi-vertical:before {
    content: '';
    position: absolute;
    top: -10px;
    height: 20px;
    left: 0;
    background: var(--brand-primary);
    width: 20px;
    border-radius: 20px;
    z-index: 1;
}

.noUi-vertical:after {
    content: '';
    position: absolute;
    bottom: -10px;
    height: 20px;
    left: 0;
    background: var(--brand-blue);
    width: 20px;
    border-radius: 20px;
    z-index: -1;
}

.noUi-marker-vertical.noUi-marker-large {
    width: 40px;
    transform: translateX(-40px);
}
