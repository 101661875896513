:root {
    --brand-blue: #E3F0F1;
    --brand-yellow: #FBCC54;
    --brand-primary: #007793;
    --brand-secondary: #343D52;
}

html, body, #root {
    height: 100%;
    color: #373737;
    background: #000;
    font-family: 'Montserrat', sans-serif;
}

.gloss-effect {
    box-shadow: 0px 3px 40px #0000004A;
    background: transparent linear-gradient(180deg, #FFE089 0%, #FFDF87 48%, #FCCF5C 50%, #FBCC54 100%) 0% 0% no-repeat padding-box;
}

.gloss-effect:hover {
    background: transparent linear-gradient(0deg, #FFE089 0%, #FFDF87 48%, #FCCF5C 50%, #FBCC54 100%) 0% 0% no-repeat padding-box;
}

.bg-brand-yellow {
    background: var(--brand-yellow);
}

.bg-brand-secondary {
    background: var(--brand-secondary);
}

.bg-brand-blue {
    background: var(--brand-blue);
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 2.25rem solid transparent;
    border-right: 2.25rem solid transparent;
    border-top: 2.25rem solid var(--brand-blue);
}

.right-1\/2 {
    right: 50%;
}

.top-full {
    top: 100%;
}

.text-brand-primary {
    color: var(--brand-primary);
}

.my-node-enter {
    opacity: 0;
}
.my-node-enter-active {
    opacity: 1;
    transition: opacity 200ms ease-in-out;
}
.my-node-exit {
    opacity: 1;
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
}
