.infographic-blur {
    transition: opacity .2s ease-in-out;
}

.bg-pyramid-red {
    fill: #D1492A;
    background: #D1492A;
}

.bg-pyramid-green {
    fill: #96C12D;
    background: #96C12D;
}

.bg-pyramid-yellow {
    fill: #FFE12E;
    background: #FFE12E;
}

.pyramid-level {
    cursor: pointer;
}
div.pyramid-level,
.pyramid-level > * {
    transition: all .2s ease-in-out;
}
div.pyramid-level.mute {
    background: #CCCCCC;
}
div.pyramid-level.mute {
    color: #FFF;
}
.pyramid-level.mute path {
    fill: #FFF;
}
.pyramid-level.mute polygon {
    fill: #CCCCCC;
}
.pyramid-level:hover .bg-pyramid-red {
    fill: #b53517;
}
.pyramid-level:hover .bg-pyramid-green {
    fill: #7ba521;
}
.pyramid-level:hover .bg-pyramid-yellow {
    fill: #e6c92b;
}
.pyramid-level:hover .bg-pyramid-yellow ~ path,
.pyramid-level:not(.mute) .bg-pyramid-yellow ~ path {
    fill: #666;
}
.pyramid-level:not(.mute) .bg-pyramid-red ~ path,
.pyramid-level:not(.mute) .bg-pyramid-green ~ path {
    fill: #FFF;
}
